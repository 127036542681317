<script setup lang="ts">
import { collection, doc, query, orderBy } from 'firebase/firestore'
import { getAnalytics, logEvent, setUserProperties, setUserId } from 'firebase/analytics'
import { usePendingPromises } from 'vuefire'

const sidebar = ref<boolean|null>(null)

const isProduction = process.env.NODE_ENV === 'production'
const db = useFirestore()
const currentUser = await getCurrentUser()
const appConfig = useAppConfig()

// STATES
const userData = useUserData()
const userState = useUserState()
const userDoc = useDocument(doc(db, 'users', currentUser.uid), { once: true })

// Products data
const productsData = useProductsData()
useCollection(collection(db, 'products'), { target: productsData })
// Plans data
const plansData = usePlansData()
useCollection(collection(db, 'plans'), { target: plansData })
// User Roles data
const rolesData = useRolesData()
useCollection(collection(db, 'roles'), { target: rolesData })
// Companies data
const companiesData = useCompaniesData()
useCollection(query(collection(db, 'companies'), orderBy('title')), { target: companiesData, ssrKey: 'companies-layout-default' })
// Users data
const usersData = useUsersData()
useCollection(collection(db, 'users'), { target: usersData })
// Typologies data
const typologiesData:Ref = useTypologiesData()
useCollection(collection(db, 'typologies'), { target: typologiesData })
// Selectors data
const selectorsData:Ref = useSelectorsData()
useDocument(doc(db, 'data', 'selectors'), { once: true, target: selectorsData })
// STATES

await usePendingPromises()
userState.setUserData(userDoc.value as FsbdUser)
const analytics = isProduction ? getAnalytics() : null

if (isProduction && analytics) {
  setUserId(analytics, currentUser.uid)
  setUserProperties(analytics, {
    user_email: userData.value?.email,
    user_role: userData.value?.refRole.name,
    user_company: userData.value?.refCompany?.name,
  })
  logEvent(analytics, 'login')
}


</script>

<template>
  <v-app>
    <NuxtLoadingIndicator />
    <v-navigation-drawer :width="appConfig.sidebarWidth ? appConfig.sidebarWidth : '210'"  v-model="sidebar">
      <template #prepend>
        <ProjectLogo />
      </template>
      <ProjectSidebar />
      <template #append>
        <ProjectConfig />
      </template>
    </v-navigation-drawer>
    <v-app-bar border="b">
      <v-app-bar-nav-icon @click="sidebar = !sidebar" />
      <ProjectAppBar />
      <v-spacer />
      <v-menu rounded>
        <template v-slot:activator="{ props }">
          <v-btn :icon="true" v-bind="props">
            <v-avatar icon="$mdiAccountOutline" color="primary" size="small" variant="outlined" />
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <div class="mx-auto text-center">
              <v-avatar color="primary" variant="outlined">
                <span class="text-lg">{{ userData?.name.substring(0, 1) }}{{ userData?.surnames.substring(0, 1) }}</span>
              </v-avatar>
              <p class="text-lg leading-5 font-bold mt-3">
                {{ userData?.name }} {{ userData?.surnames }}<br>
                <span class="text-sm font-normal">{{ userData?.refRole.name }}</span>
              </p>
              <v-divider class="my-3" />
              <p class="text-sm mt-1">{{ userData?.email }}<br>
                {{ userData?.refCompany?.name }}<br>

              </p>
              <v-divider class="my-3" />
              <NuxtLink to="/terms" class="text-xs text-primary hover:underline" target="_blank">Términos y condiciones</NuxtLink><br>
              <NuxtLink to="/privacy" class="text-xs text-primary hover:underline" target="_blank">Política de privacidad</NuxtLink>
              <v-divider class="my-3" />
              <LazyLayoutExitButton text="SALIR" />
            </div>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-container class="min-h-full w-full flex flex-row">
        <div class="pt-2 pb-14 px-3 w-full">
          <slot />
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
