<script setup lang="ts">
const { isSuperadmin, isAdmin, userData } = useUserState()
const db = useFirestore()
const products = useProductsList(db)
const briefings = useBriefingsList(db)

// if isSuperadmin or refCompany has a bucket or refCompany status is demo
const hasInsights = computed(() => {
  return isSuperadmin || isAdmin || (!isSuperadmin && !!userData.value.refCompany.bucket) || (!isSuperadmin && userData.value.refCompany.status === 'demo')
})

</script>

<template>
  <v-list>
    <v-divider v-if="briefings.length" />
    <v-list-item v-if="briefings.length" title="Briefings" prepend-icon="$mdiListbox" to="/briefings" />
    <v-divider v-if="products.length" />
    <v-list-item v-if="products.length" title="Productos" prepend-icon="$mdiDashboard" to="/products" />
    <v-divider v-if="hasInsights" />
    <v-list-item v-if="hasInsights" title="Recomendaciones" prepend-icon="$mdiLightbulbOnOutline" to="/insights" />
    <v-divider />
    <v-list-item title="Demos" prepend-icon="$mdiDashboard" to="/demostraciones" />
    <v-divider />
    <v-list-item title="Precios" prepend-icon="$mdiProgressCheck" to="/pricing" />
    <v-divider />
    <v-list-item active title="Contactar" variant="outlined" color="primary" rounded prepend-icon="$mdiFileDocumentEdit" to="/contact" />
    <v-divider />
    <v-list-item v-if="isSuperadmin || isAdmin" title="Analytics" prepend-icon="$mdiAnalytics" to="/analytics" />
    <v-divider v-if="isSuperadmin || isAdmin" />
    <v-divider />
  </v-list>
</template>
