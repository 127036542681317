<script setup lang="ts">
const { isSuperadmin, isAdmin } = useUserState()
</script>

<template>
  <v-list class="!p-0">
    <v-divider />
    <v-list-group v-if="isSuperadmin || isAdmin">
      <template v-slot:activator="{ props }">
        <v-list-item title="Configuración" v-bind="props" prepend-icon="$mdiCog" />
      </template>
      <v-divider />
      <v-list-item title="Productos" prepend-icon="$mdiProducts" to="/config/products" />
      <v-divider />
      <v-list-item title="Planes" prepend-icon="$mdiPlaylistCheck" to="/config/plans" />
      <v-divider />
      <v-list-item title="Empresas" prepend-icon="$mdiAccountTie" to="/config/companies" />
      <v-divider />
      <v-list-item title="Usuarios" prepend-icon="$mdiAccount" to="/config/users" />
      <v-divider />
      <v-list-item title="Registros" prepend-icon="$mdiAccount" to="/config/signups" />
    </v-list-group>
  </v-list>
</template>